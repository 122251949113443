body {
  margin: 0px;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(-90deg, #117bd2, #8eeaff); */
  height: 100vh;
  overflow-x: hidden;
/* Gradient in the spectrum of grey and white */
/* Gradient in the spectrum of grey and soft white */
/* background: linear-gradient(-90deg, #b0b0b0, #e0e0e0); */
background-color: rgb(231, 238, 245);

}

p {
  font-size: 0.8em;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
